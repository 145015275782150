<template>
  <div class="DashBoard">
    <div
      v-if="carregando == true"
      class="carregando"
    >
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div>
      <Topo
        @OpenMenuLateral="menuOpen = true"
        v-if="showHeader"
      ></Topo>

      <ConteudoDash
        :enderecoCarregado="enderecoCarregado"
        :estados="estados"
        :cidades="cidades"
        :categorias="categorias"
        @OpenModalOferta="OpenModalOferta"
      ></ConteudoDash>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        v-if="showFooter"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <b-modal
      v-model="modalDetalhe"
      hide-footer
      centered
      :title="itemDetalhe.Nome"
      ><div class="mb-5">
        <DetalheOferta
          :itemOferta="itemDetalhe"
          :itemClick="itemClick"
        ></DetalheOferta>
      </div>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
import Topo from "./Topo";
import MenuRodape from "./MenuRodape";
import ConteudoDash from "./ConteudoDash";
import MenuLateral from "./MenuLateral";
import DetalheOferta from "./DetalheOferta";
import axios from "axios";
//import MenuInterno from "./MenuInterno";
import service from "../services";
export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  data() {
    return {
      carregando: false,
      decoded: {},
      form: {},
      menuOpen: false,
      enderecoCarregado: false,
      modalDetalhe: false,
      itemDetalhe: {},
      itemClick: null,
      estados: [],
      cidades: [],
      categorias: [],
      showHeader: true,
      showFooter: true,
    };
  },
  components: {
    Topo,
    ConteudoDash,
    MenuRodape,
    MenuLateral,
    DetalheOferta,
  },
  methods: {
    toBoolean(value) {
      if (value === "false" || value === "0") {
        return false;
      }
      return true;
    },
    verificaToken() {
      document.body.className = "logado";
      const token = localStorage.getItem("user-token");
      if (token) {
        this.decoded = jwt.decode(token);
      }
      this.showHeader = this.toBoolean(localStorage.getItem("showHeader"));
      this.showFooter = this.toBoolean(localStorage.getItem("showFooter"));
    },
    OpenModalOferta(item) {
      this.itemClick = item;
      this.carregando = true;
      let empresaData = JSON.parse(localStorage.getItem("dadosEmpresa"));
      var action = "detalheEstabelecimento";
      service
        .post("Fornecedores", action, {
          codigoEstabelecimento: item.Codigo,
          cpf: localStorage.getItem("cpf"),
          empresa: empresaData.Codigo,
        })
        .then((res) => {
          this.modalDetalhe = true;
          this.itemDetalhe = res.data;
          this.carregando = false;
        });
    },
    dontGetPositin() {
      this.carregando = false;
      this.enderecoCarregado = true;
      localStorage.removeItem("latitude");
      localStorage.removeItem("logitude");
    },
    async getLocation() {
      const codigoEstado = localStorage.getItem("codigoEstado");
      var estados = await this.getEstados();
      if (codigoEstado) {
        var auxEstados = estados.find((x) => x.Codigo == codigoEstado);
        if (auxEstados) {
          var cidades = await this.getCidades(auxEstados.Codigo);
        }
        this.carregando = false;
        this.enderecoCarregado = true;
      } else if (navigator.geolocation) {
        this.carregando = true;
        navigator.geolocation.getCurrentPosition(this.showPosition, this.dontGetPositin);
      }
    },
    async showPosition(position) {
      this.form.cordenadas = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        altitude: null,
        altitudeAccuracy: null,
        accuracy: 43,
      };
      localStorage.setItem("latitude", position.coords.latitude);
      localStorage.setItem("logitude", position.coords.longitude);
      var reverse = await this.reverseCoordenates(position.coords.latitude, position.coords.longitude);

      var estados = await this.getEstados();
      var auxEstados = estados.find((x) => x.Estado == reverse.data.address.state);
      if (auxEstados) {
        localStorage.setItem("codigoEstado", auxEstados.Codigo);
        localStorage.setItem("estado", auxEstados.Estado);
        var cidades = await this.getCidades(auxEstados.Codigo);
      }
      this.carregando = false;
      this.enderecoCarregado = true;
    },
    reverseCoordenates(lat, log) {
      this.carregando = true;
      return new Promise((resolve, reject) => {
        var end = `https://nominatim.openstreetmap.org/reverse?&format=json&lat=${lat}&lon=${log}&zoom=21&addressdetails=1`;
        axios
          .get(end)
          .then((res) => {
            this.carregando = false;
            resolve(res);
          })
          .catch((e) => {
            this.carregando = false;
            reject(e);
          });
      });
    },
    async getEstados() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        var action = "Listar";
        service.post("Estados", action).then((res) => {
          this.estados = res.data;
          this.estados.unshift({ Codigo: null, Estado: "Estado" });
          this.carregando = false;
          resolve(this.estados);
        });
      });
    },
    async getCidades(estado) {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        var action = "Listar";
        service.post("Cidades", action, { codigoEstado: estado }).then((res) => {
          this.cidades = res.data;
          this.cidades.unshift({ Codigo: null, Cidade: "Cidade" });
          this.carregando = false;
          resolve(this.cidades);
        });
      });
    },
    async getCategorias() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        var action = "Listar";
        service.post("Categorias", action).then((res) => {
          this.categorias = res.data;
          this.categorias.unshift({ Codigo: null, Nome: "Categoria" });
          this.carregando = false;
          resolve(this.categorias);
        });
      });
    },
  },
  mounted() {
    this.verificaToken();
    this.getLocation();
    this.getCategorias();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style
  lang="scss"
  scoped
>
.iconeFechar {
  font-size: 28px;
}
.bm-menu {
  background: #373a47;
}
.bm-menu {
  background-color: #3f3f41;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.2s;
  width: 0;
  z-index: 1039;
  color: #fff;
}
.bm-item-list > * {
  display: flex;
  padding: 0.7em;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.bm-item-list > * > i {
  color: #666;
  font-weight: 700;
  margin-left: 10px;
}
.logoBottom {
  margin-top: 100px;
  text-align: center;
  opacity: 0.5;
}
</style>
